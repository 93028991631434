import { useState, useEffect } from 'react'
import { breakpoints as breakpointSettings } from 'utils/theme'

type Breakpoints = {
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  '2xl': boolean
}

type BreakpointsReturn = {
  below: Breakpoints
  above: Breakpoints
}

const useBreakpoints = (): BreakpointsReturn => {
  const [breakpoints, setBreakpoints] = useState<BreakpointsReturn>({
    below: {
      sm: false,
      md: false,
      lg: false,
      xl: false,
      '2xl': false,
    },
    above: {
      sm: false,
      md: false,
      lg: false,
      xl: false,
      '2xl': false,
    },
  })

  useEffect(() => {
    const updateBreakpoints = () => {
      const newBreakpoints: BreakpointsReturn = {
        below: Object.entries(breakpointSettings).reduce(
          (acc, [breakpoint, width]) => {
            return {
              ...acc,
              [breakpoint]: !window.matchMedia(`(min-width: ${width}px)`)
                .matches,
            }
          },
          {} as Breakpoints,
        ),
        above: Object.entries(breakpointSettings).reduce(
          (acc, [breakpoint, width]) => {
            return {
              ...acc,
              [breakpoint]: !window.matchMedia(`(max-width: ${width}px)`)
                .matches,
            }
          },
          {} as Breakpoints,
        ),
      }
      setBreakpoints(newBreakpoints)
    }

    updateBreakpoints()
    window.addEventListener('resize', updateBreakpoints)

    return () => {
      window.removeEventListener('resize', updateBreakpoints)
    }
  }, [])

  return breakpoints
}

export default useBreakpoints
