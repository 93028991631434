import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export enum PlatformMode {
  Lite = 'lite',
  Pro = 'pro',
}

export type SettingsStore = {
  hasBlurOverlay: boolean
  isCollapsed: boolean
  platformMode: PlatformMode
  updateProp: <TKey extends keyof SettingsStore>(
    key: TKey,
    value: SettingsStore[TKey],
  ) => void
  isPro: () => boolean
}

const ALLOW_PRO_MODE =
  process.env.NEXT_PUBLIC_ALLOW_PRO_MODE === 'true' ||
  process.env.NODE_ENV === 'development'

const useSettingsStore = create<SettingsStore>()(
  persist(
    (set, get) => ({
      isCollapsed: true,
      hasBlurOverlay: false,
      platformMode: PlatformMode.Lite,
      updateProp: (key, value) => {
        if (key === 'platformMode' && !ALLOW_PRO_MODE) {
          return
        }

        set({ [key]: value })
      },
      isPro: () => get().platformMode === PlatformMode.Pro,
    }),
    {
      name: 'settings',
      partialize: (state) => {
        return {
          isCollapsed: state.isCollapsed,
          hasBlurOverlay: state.hasBlurOverlay,
          platformMode: state.platformMode,
        }
      },
    },
  ),
)

export default useSettingsStore
